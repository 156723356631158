import { required, email, minLength } from "vuelidate/lib/validators";
import { TweenMax } from "gsap";

export default {
  name: "HireCreator",
  data() {
    return {
      requestResponse: {},
      passwordState: "password",
      email: "",
      password: "",
      document: "",
      name: "",
      phone: "+55",
      check: false,
      company: "",
      step: 1,
      checkError: false,
      registered: false,
      sync: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
    company: {
      required,
    },
    document: {
      required,
      minLength: minLength(18),
    },
    name: {
      required,
    },
    phone: {
      required,
      minLength: minLength(15),
    },
  },
  methods: {
    animationStep() {
      const { container, step1, step2 } = this.$refs;

      TweenMax.to(step1, 0.5, {
        y: "-100%",
        opacity: 0,
        overflow: "hidden",
        height: 0,
      });
      TweenMax.to(step2, 0.5, {
        height: "auto",
        opacity: 1,
      });
      this.step = 2;
    },
    handleChange(field, value) {
      if (field == this.document) this.registered = false;
      this[field] = value;
      this.$v[field] &&
        this.$v[field].$params.required &&
        this.$v[field].$touch();
    },
    changePass() {
      if (this.passwordState === "password") {
        this.passwordState = "text";
      } else {
        this.passwordState = "password";
      }
    },
    changeStep() {
      const { container, step1, step2 } = this.$refs;

      this.$v.email.$touch();
      this.$v.password.$touch();

      if (
        this.step === 1 &&
        !this.$v.email.$invalid &&
        !this.$v.password.$invalid &&
        this.check
      ) {
        TweenMax.to(step1, 0.5, {
          y: "-100%",
          opacity: 0,
          overflow: "hidden",
          height: 0,
        });
        TweenMax.to(step2, 0.5, {
          height: "auto",
          opacity: 1,
        });
        this.step = 2;
      }
    },
    async submitForm() {
      this.sync = true;
      if (this.step === 1) {
        this.$v.email.$touch();
        this.$v.password.$touch();
        if (!this.check) {
          this.checkError = true;
        }
        if (
          !this.$v.email.$invalid &&
          !this.$v.password.$invalid &&
          this.check
        ) {
          try {
            this.requestResponse = await axios({
              method: "POST",
              header: {
                "Content-Type": "aplication/json",
              },
              url: "/api/v1/companies",
              data: {
                email: this.email,
                password: this.password,
                origin: "creators",
              },
            });
            this.sync = false;
            if (this.requestResponse.data.error) {
              console.log(this.requestResponse.data.error);
              if (this.requestResponse.data.error.email)
                alert(
                  "E-mail já cadastrado. Caso você não lembre a senha, acesse o link de esqueci minha senha na tela de login."
                );
              return;
            } else {
              this.$store.commit(
                "setToken",
                this.requestResponse.data.data.access_token
              );
              this.$store.commit("setMe", this.requestResponse.data.data.user);
            }
          } catch (err) {}
          //LOADER
          this.animationStep();
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          console.log("Valid");

          try {
            let update = await axios({
              method: "POST",
              url: `/api/v1/companies/${this.requestResponse.data.data.user.company_id}`,
              headers: {
                Authorization: `Bearer ${this.requestResponse.data.data.access_token}`,
              },
              data: {
                _method: "PUT",
                name: this.name,
                phone: this.phone,
                nome_fantasia: this.company,
                cnpj: this.document,
                origin: "creators",
              },
            });
            if (update.data.error && update.data.error.cnpj) {
              this.registered = true;
              alert("CNPJ já cadastrado.");
            } else {
              this.$emit("close-modal", "login");
              $("html").css("overflow", "auto");
              // this.$router.push("dashboard/client/");
              window.location = "/dashboard/client"
            }
          } catch (err) {
            // Error treatment
          }
        }
      }
    },
  },
};
