<template>
  <section>
    <div ref="container" class="container-slide">

      <!-- STEP 1 -->
      <div v-if="!showReset && !showInsertPass" ref="step1" class="step-1">
        <!-- <h3>Acesse usando uma das opções abaixo</h3>
        <div class="services-container">
          <home-button type="primary" v-on:click.native="authenticate('facebook')">
            <img src="/images/socials/facebook.svg" alt="Facebook Icon" />
            <span class="only-desk">Facebook</span>
          </home-button>
          <home-button type="primary" v-on:click.native="authenticate('google')">
            <img src="/images/socials/google.svg" alt="Google Icon" />
            <span class="only-desk">Google</span>
          </home-button>
          <home-button type="primary">
            <img src="/images/socials/linkedin.svg" alt="LinkedIn Icon" />
            <span class="only-desk">LinkedIn</span>
          </home-button>
        </div>
        <h3>ou</h3> -->
        <form class="form-container" v-on:submit.prevent="onSubmit">

          <div class="input-container mb-5">
            <home-input
              v-model.trim="$v.email.$model"
              class="custom-input"
              :label="$t('home.login.label_email')"
              name="email"
              type="email"
              :value="email"
              @change="handleChange"
            />
            <div class="error">
              <span v-if="incorretCredentials">{{ $t("home.login.error_1") }}</span>
              <span v-if="!$v.email.required && $v.email.$dirty">{{ $t("home.login.error_2") }}</span>
              <span v-if="!$v.email.email && $v.email.$dirty">{{ $t("home.login.error_3") }}</span>
            </div>
          </div>
          <div class="input-container mb-5">
            <div class="pass-container">
              <home-input
                class="custom-input pass-input"
                :label="$t('home.login.label_senha')"
                name="password"
                :type="passwordState"
                :value="password"
                @change="handleChange"
              /><img src="https://storage.googleapis.com/creators-static-1/images/eye.svg" @click="changePass()" />
            </div>
            <div class="error">
              <span v-if="!$v.password.required && $v.password.$dirty">{{
                $t("home.login.error_2")
              }}</span>
            </div>
            <a class="forgot-pass mt-3 d-block" @click="showReset = true">{{
              $t("home.login.forgot_pass")
            }}</a>
            <div class="bottom-container">
              <div class="buttons-container mt--30">
                <home-button
                  :submit="true"
                  type="primary"
                  @click.native="onSubmit()"
                  >Login</home-button
                >
              </div>
            </div>
          </div>

          <div class="mb-5">
            <h3>Social login</h3>
            <home-button type="primary" v-on:click.native="authenticate('facebook')">
              <img src="/images/socials/facebook.svg" alt="Facebook Icon" />
              <span class="only-desk">Facebook</span>
            </home-button>
          </div>

          <h3>{{ $t("home.login.cadastro") }}</h3>
          <div class="services-container">
            <home-button type="secondary" @click.native="changeModal('be')">
              {{ $t("home.be") }}
              <span class="only-desk">Creators</span>
            </home-button>
            <home-button type="secondary" @click.native="changeModal('hire')">
              {{ $t("home.hire") }}
              <span class="only-desk">Creators</span>
            </home-button>
          </div>
        </form>
      </div>

      <!-- STEP 2 -->
      <div v-if="showReset && !showInsertPass" ref="step2" class="step-1">
        <h3>{{ $t("home.login.forgot_pass_2") }} :)</h3>
        <div class="form-container">
          <div class="incorrect-credentials">
            <span v-if="incorretCredentials">{{
              $t("home.login.error_1")
            }}</span>
          </div>
          <div class="input-container">
            <home-input
              v-model.trim="$v.email.$model"
              class="custom-input"
              :label="$t('home.login.confirme')"
              name="email"
              type="email"
              :value="email"
              @change="handleChange"
            />
            <div class="error">
              <span v-if="!$v.email.required && $v.email.$dirty">{{
                $t("home.login.error_2")
              }}</span>
              <span v-if="!$v.email.email && $v.email.$dirty">{{
                $t("home.login.error_3")
              }}</span>
              <span v-if="!$v.email.email && $v.email.$notregistered">{{
                $t("home.login.error_4")
              }}</span>
            </div>
          </div>
          <div class="bottom-container">
            <div class="buttons-container" @click="resetPassword">
              <home-button type="primary change-value">{{
                $t("home.login.send_2")
              }}</home-button>
            </div>
          </div>
          <div class="submit-txt"><p class="purple"></p></div>
        </div>
      </div>
      <div v-if="!showReset && showInsertPass" ref="step2" class="step-1">
        <h3>{{ $t("home.login.new_pass") }}</h3>
        <div class="form-container">
          <div class="incorrect-credentials">
            <span v-if="incorretCredentials">{{
              $t("home.login.error_1")
            }}</span>
          </div>
          <div class="input-container">
            <home-input
              v-model.trim="$v.email.$model"
              class="custom-input"
              :label="$t('home.login.label_email')"
              name="email"
              type="email"
              :value="email"
              @change="handleChange"
            />
            <div class="error">
              <span v-if="!$v.email.required && $v.email.$dirty">{{
                $t("home.login.error_2")
              }}</span>
              <span v-if="!$v.email.email && $v.email.$dirty">{{
                $t("home.login.error_3")
              }}</span>
            </div>
          </div>
          <div class="input-container">
            <div class="pass-container">
              <home-input
                class="custom-input pass-input"
                :label="$t('home.login.new_pass_2')"
                name="password"
                :type="passwordState"
                :value="password"
                @change="handleChange"
              /><img src=/images/eye.svg @click="changePass()" />
            </div>
            <div class="error">
              <span v-if="!$v.password.required && $v.password.$dirty">{{
                $t("home.login.error_2")
              }}</span>
            </div>
          </div>
          <div class="bottom-container">
            <div class="buttons-container" @click="sendNewPassword">
              <home-button type="primary">{{ $t("dash.salvar") }}</home-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { TweenMax, TimelineMax } from "gsap";

export default {
  name: "Login",
  data() {
    return {
      requestResponse: null,
      email: "",
      password: "",
      resetToken: "",
      step: 1,
      passwordState: "password",
      incorretCredentials: false,
      showReset: false,
      showInsertPass: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  mounted() {
    var _this = this;
    if (_this.$route.query.reset_token) {
      axios
        .get("/api/password/find/" + _this.$route.query.reset_token)
        .then((response) => {
          if (response.data.data) {
            _this.showInsertPass = true;
            _this.email = _this.$route.query.address
              ? _this.$route.query.address
              : "";
            _this.resetToken = _this.$route.query.reset_token;
          }
          if (response.data.error) {
            alert(response.data.error.message);
          }
        });
    }
  },
  methods: {
    changePass() {
      if (this.passwordState === "password") {
        this.passwordState = "text";
      } else {
        this.passwordState = "password";
      }
    },

    changeModal(modalName) {
      const { step1 } = this.$refs;
      const t1 = new TimelineMax({
        onComplete: () => this.$emit("change-modal", modalName),
      });
      t1.to(step1, 0.5, {
        opacity: 0,
      });
    },

    authenticate: function (provider) {
      var _this = this;
      if (_this.$auth.isAuthenticated()) {
        _this.$auth.logout();
      }
      this.$auth
        .authenticate(provider)
        .then((response) => {
          axios
            .post("/auth/" + provider + "/callback", {
              access_token: response.data.access_token,
            })
            .then((response) => {
              if (response.data.data) {
                _this.success(response.data);
              }
              if (response.data.error) {
                _this.errors.fail = true;
              }
            });
        })
        .catch((err) => {
          console.log({ err: err });
        });
    },

    resetPassword() {
      var _this = this;
      if (!_this.email) {
        alert("Insira um e-mail");
        return;
      }
      axios
        .post("/api/password/create", {
          email: _this.email,
        })
        .then((response) => {
          if (response.data.data) {
            $(".submit-txt p").text(response.data.data.message);
            $(".change-value").text("Enviado!");
            // alert(response.data.data.message);
          }
          if (response.data.error) {
            $(".submit-txt p").text(response.data.error.message);
            // alert(response.data.error.message);
          }
        });
    },

    sendNewPassword() {
      var _this = this;
      if (!_this.email) {
        alert("Insira um e-mail");
        return;
      }
      if (!_this.password) {
        alert("Insira uma nova senha");
        return;
      }
      if (!_this.resetToken) {
        alert("Token inválido");
        return;
      }
      axios
        .post("/api/password/reset", {
          email: _this.email,
          password: _this.password,
          token: _this.resetToken,
        })
        .then((response) => {
          if (response.data.data) {
            _this.success(response.data);
          }
          if (response.data.error) {
            alert(response.data.error.message);
          }
        });
    },

    success: function (data) {
      var _this = this;
      // save token for future use
      _this.$store.commit("setToken", data.data.access_token);
      // save me for future use
      _this.me = data.data.user;
      _this.$store.commit("setMe", data.data.user);
      // enable scroll
      this.$emit("close-modal", "login");
      const doc = document.querySelector("body");
      doc.style.overflow = this.opened ? "hidden" : "auto";
      // redirect user to dashboard
      if (_this.me.admin) {
        // _this.$router.push({ path: "/dashboard/admin" });
        window.location = "/dashboard/admin";
      } else if (_this.me.company_id) {
        if (!_this.me.cpf || _this.me.cpf == "")
          // _this.$router.push({ path: "/dashboard/client/settings" });
          window.location = "/dashboard/client/settings";
          // else _this.$router.push({ path: "/dashboard/client" });
          else window.location = "/dashboard/client";
      } else {
        // _this.$router.push({ path: "/dashboard/professional" });
        window.location = "/dashboard/professional";
      }
    },

    async onSubmit() {
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === 401) {
            this.incorretCredentials = true;
            alert("Credenciais incorretas. Verifique seu e-mail e senha.");
          }
          return error;
        }
      );

      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          // Submition requests
          this.requestResponse = await axios({
            method: "POST",
            url: "/api/auth/login",
            data: {
              email: this.email,
              password: this.password,
              remember_me: true,
            },
          });
          console.table(this.requestResponse);
          this.$store.commit(
            "setToken",
            this.requestResponse.data.data.access_token
          );
          this.$store.commit("setMe", this.requestResponse.data.data.user);
          // localStorage.setItem(
          //   "@creators:user",
          //   JSON.stringify(this.requestResponse.data.data.user)
          // );
          this.$emit("close-modal", "login");
          $("html").css("overflow", "auto");
          let response = this.requestResponse.data.data;
          let me = response.user;
          if (me.admin) {
            // this.$router.push({ path: "/dashboard/admin" });
            window.location = "/dashboard/admin";
          } else if (me.company_id) {
            if (!me.profile.cpf || me.profile.cpf == "")
              // this.$router.push({ path: "/dashboard/client/settings" });
              window.location = "/dashboard/client/settings";
            // else this.$router.push({ path: "/dashboard/client" });
            else window.location = "/dashboard/client";
          } else {
            if (!me.name || me.name == "" || me.specialities.length == 0)
              // this.$router.push({ path: '/dashboard/professional/settings' });
              window.location = '/dashboard/professional/settings';
            else {
              if (this.$route.query.briefing) {
                window.location = "/dashboard/professional/briefing/" + this.$route.query.briefing;
              } else {
                window.location = "/dashboard/professional";
              }
            }


          }
        } catch (err) {
          console.log(err);
        }
        // enable scroll
        const doc = document.querySelector("body");
        doc.style.overflow = this.opened ? "hidden" : "auto";
      }
    },
    handleChange(field, value) {
      this[field] = value;
      this.$v[field] &&
        this.$v[field].$params.required &&
        this.$v[field].$touch();
    },
    changeStep() {
      const { container, step1, step2 } = this.$refs;

      if (this.step === 1) {
        TweenMax.to(step1, 0.5, {
          opacity: 0,
        });
        TweenMax.to(step2, 0.5, {
          opacity: 1,
        });
        this.step = 2;
      } else {
        TweenMax.to(step2, 0.5, {
          opacity: 0,
        });
        TweenMax.to(step1, 0.5, {
          opacity: 1,
        });
        this.step = 1;
      }
      TweenMax.to(container, 0.5, {
        y: `-${(this.step - 1) * 100}%`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes startOnScreen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
section {
  margin-top: 40px;
  height: 100%;
  .container-slide {
    overflow: visible;
    min-height: 90%;
    .step-1 {
      height: 100%;
    }
    .step-2 {
      height: 100%;
      opacity: 0;
    }
    h3 {
      margin-bottom: 1em;
      color: #ffffff;
      font-size: 1.5em;
    }
    .services-container {
      position: absolute;
      display: flex;
      margin-bottom: 40px;
      button {
        margin-right: 20px;
        img {
          transition: all 0.2s ease-in-out;
          margin-right: 20px;
        }
      }
      .only-desk {
        margin-left: 3px;
        transition: all 0.2s ease-in-out;
      }
      @media screen and (max-width: 768px) {
        button {
          img {
            margin-right: 0;
          }
        }
        .only-desk {
          max-width: 0;
          opacity: 0;
          transform: translateX(-20px);
          overflow: hidden;
          margin: 0;
        }
      }
    }
    .form-container {
      // width: 60%;
      .custom-input {
        margin-bottom: 0px !important;
      }
      .input-container {
        margin-bottom: 32px;
        .forgot-pass {
          color: #B59DFF;
        }
        .pass-container {
          position: relative;
          width: 100%;
          display: flex;
          .pass-input {
            width: 100%;
          }
          img {
            cursor: pointer;
            width: 26px;
            height: 26px;
            position: absolute;
            right: 10px;
            top: 5px;
          }
        }
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .error {
        height: 16px;
        font-family: "SF Pro Display";
        color: #DA5356;
      }
      .incorrect-credentials {
        height: 28px;
        color: #B59DFF;
        span {
          animation: startOnScreen 0.5s ease-in-out;
        }
      }
    }
  }
  .bottom-container {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;
    span {
      color: #ffffff;
    }
    .buttons-container {
      display: flex;
      a {
        text-decoration: none;
      }
      button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .background {
      background-color: #ffffff;
      height: 5px;
      width: 200px;
      .bar {
        background-color: #B59DFF;
        height: 5px;
        margin-top: 10px;
        transition: width 0.2s ease-in-out;
        &.half {
          width: 100px;
        }
        &.full {
          width: 200px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      position: static;
      justify-content: flex-start;
      width: 100%;
    }
  }
  span {
    font-size: 1em !important;
  }
  @media (max-width: 768px) {
    .bottom-container {
      justify-content: flex-end;
    }
  }
}
</style>
