<template>
  <section class="container for-services">
    <div class="for-slide">
      <div class="opt">
        <img src="https://storage.googleapis.com/creators-static-1/images/home/wand.svg" />
      </div>
      <div class="title-for-service">
        <div @click="goPrev">
          <h2 class="for-title-1 active">{{ $t('home.product.be') }}</h2>
          <div class="for-myProgress">
            <div class="for-myBar-1 status active"></div>
          </div>
        </div>
        <div @click="goNext">
          <h2 class="for-title-2">{{ $t('home.product.hire') }}</h2>
          <div class="for-myProgress">
            <div class="for-myBar-2 status"></div>
          </div>
        </div>
      </div>
      <div>
        <swiper :options="swiper" ref="swiper">
          <swiper-slide class="swiper-slide" id="tab-1">
            <div class="group">
              <div>
                <p>{{ $t('home.product.title1') }}</p>
              <a href="/?openlogin=true">  <div class="for-btn-cad">
                  <p>Seja um Creator</p>
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </div></a>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="swiper-slide" id="tab-2">
            <div class="group">
              <div>
                <p>{{ $t('home.product.title2') }}</p>
                <a href="/?openlogin=true"><div class="for-btn-cad">
                  <p>Contrate um Creator</p>
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </div></a>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
    <!-- d-none d-lg-block -->
    <img class="desktop" src="https://storage.googleapis.com/creators-static-1/images/home/dashboard.png"/>
    <!-- <video
      @mouseover="$gtag.event('video-demo')"
      id="video-demo"
      class="desktop"
      preload="metadata"
      src="https://storage.googleapis.com/creators-static-1/videos/demo-mini.mp4"
      poster="https://storage.googleapis.com/creators-static-1/images/dashboard/agrupar-1.webp"
      onmouseover="this.play(); this.style.opacity = 1;"
      onmouseout="this.pause(); this.style.opacity = 0.01;"
      controls
    ></video> -->
    <!-- <img
      class="phone"
      src="https://storage.googleapis.com/creators-static-1/images/dashboard/group-2.png"
    /> -->
  </section>
</template>

<script>
import $ from "jquery";
var timer = null;
export default {
  data() {
    return {
      swiper: {
        slidesPerView: "auto",
        slidesPerGroup: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  mounted: function () {
    this.$nextTick(function () {
      timer = window.setInterval(() => {
        const tabActive1 = document.getElementById("tab-1");
        const tabActive2 = document.getElementById("tab-2");
        console.log(tabActive1.classList[1]);
        console.log(tabActive2.classList[1]);
        if (tabActive1.classList[1] === "swiper-slide-active") {
          $(".swiper-button-next").click();
          const tabAtcive1 =
            document.getElementsByClassName("for-myBar-1 status");
          const tabAtcive2 =
            document.getElementsByClassName("for-myBar-2 status");
          const titleTabAtcive1 =
            document.getElementsByClassName("for-title-1");
          const titleTabAtcive2 =
            document.getElementsByClassName("for-title-2");
          tabAtcive2[0].classList.add("active");
          tabAtcive1[0].classList.remove("active");
          titleTabAtcive2[0].classList.add("active");
          titleTabAtcive1[0].classList.remove("active");
        } else {
          $(".swiper-button-prev").click();
          const tabAtcive1 =
            document.getElementsByClassName("for-myBar-1 status");
          const tabAtcive2 =
            document.getElementsByClassName("for-myBar-2 status");
          const titleTabAtcive1 =
            document.getElementsByClassName("for-title-1");
          const titleTabAtcive2 =
            document.getElementsByClassName("for-title-2");
          tabAtcive1[0].classList.add("active");
          tabAtcive2[0].classList.remove("active");
          titleTabAtcive1[0].classList.add("active");
          titleTabAtcive2[0].classList.remove("active");
        }
      }, 6000);
    });
  },
  methods: {
    pauseSlider() {
      const { swiper } = this.$refs;
      swiper.swiper.autoplay.stop();
    },
    startSlider() {
      const { swiper } = this.$refs;
      swiper.swiper.autoplay.start();
    },
    goPrev() {
      console.log(timer);
      console.log("prev=", $(".swiper-button-prev"));
      //  $(".swiper-button-prev").trigger("click");
      $(".swiper-button-prev").click();
      const tabAtcive1 = document.getElementsByClassName("for-myBar-1 status");
      const tabAtcive2 = document.getElementsByClassName("for-myBar-2 status");
      const titleTabAtcive1 = document.getElementsByClassName("for-title-1");
      const titleTabAtcive2 = document.getElementsByClassName("for-title-2");
      tabAtcive1[0].classList.add("active");
      tabAtcive2[0].classList.remove("active");
      titleTabAtcive1[0].classList.add("active");
      titleTabAtcive2[0].classList.remove("active");
      clearInterval(timer);
    },
    goNext() {
      console.log("Next");
      //  $("swiper-button-next").trigger("click");
      $(".swiper-button-next").click();
      const tabAtcive1 = document.getElementsByClassName("for-myBar-1 status");
      const tabAtcive2 = document.getElementsByClassName("for-myBar-2 status");
      const titleTabAtcive1 = document.getElementsByClassName("for-title-1");
      const titleTabAtcive2 = document.getElementsByClassName("for-title-2");
      tabAtcive2[0].classList.add("active");
      tabAtcive1[0].classList.remove("active");
      titleTabAtcive2[0].classList.add("active");
      titleTabAtcive1[0].classList.remove("active");
      clearInterval(timer);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-areas: "texts photos";
  transition: all 0.2s ease-in-out;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 100% 0;
    margin: 40px;
  }
  @media (max-width: 768px) {
    width: 100%;
    display: block;
    margin: 0px;
    padding: 20px 0px 0px 40px;
  }
  h2 {
    margin-bottom: 0;
    font-size: 16px;
    line-height: normal;
    color: #3e465f;
    font-weight: bold;
    margin-top: 40px;
        cursor: pointer;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  h3 {
    margin-bottom: 0;
    font-size: 1.5em;
    line-height: normal;
    color: #ffffff;
    font-weight: bold;
    margin-top: 40px;
  }
  p {
    line-height: 1.3em;
    font-weight: 600;
    word-spacing: 1px;
    font-size: 1.1em;
    color: #999;
  }
  h2.for-title-2.active {
    color: #ffffff;
  }
  h2.for-title-1.active {
    color: #ffffff;
  }
  .for-myBar-1.status.active {
    width: 100%;
    transition: all 6s ease-in-out;
  }
  .for-myBar-2.status.active {
    width: 100%;
    transition: all 6s ease-in-out;
  }
  .title-for-service {
    display: flex;
    width: 100%;
  }
  .for-slide {
    background: #000;
    margin-right: 6px;
    padding: 20px;
    border-radius: 19px;
    height: 84%;
}
  .title-for-service > div {
    margin-right: 30px;
    @media (max-width: 768px) {
      margin-right: 30px;
      width: 50%;
    }
  }
  video {
    cursor: pointer;
    opacity: 0.01;
    @media (max-width: 768px) {
      display: none;
    }
  }
  img,
  video {
    z-index: 1;
    grid-area: photos;
    max-width: 1500px;
    align-self: end;
    transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
    &:hover {
      // transform: scale(1.05);
      ~ img {
        transform: translateX(400%);
      }
    }
    &.desktop {
      width: 100%;
      margin-bottom: 75px;
      @media (max-width: 768px) {
        width: 900px;
        margin-bottom: 75px;
        margin-left: 26px;
      }
    }
    &.phone {
      max-width: 300px;
      width: 25%;
      justify-self: end;
      margin-right: 0px;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .swiper-wrapper {
    transition-timing-function: linear;
  }
  .for-myProgress {
    width: 100%;
    background-color: #3e465f;
    margin-bottom: 20px;
  }
  .opt {
    width: 20%;
  }
  .status {
    width: 0%;
    height: 3px;
    background-color: #ffffff;
    transition: all 0s ease-in-out;
  }
  .for-myBar-1.status .active {
    width: 100%;
    height: 3px;
    background-color: #ffffff;
  }
  .for-myBar-2.status .active {
    width: 100%;
    height: 3px;
    background-color: #ffffff;
  }
  .opt img {
    width: auto;
    height: auto;
    max-width: 40px;
    border-radius: 50%;
  }
  .opt p {
    font-size: 26px;
    line-height: 38px;
  }
  .opt i {
    color: white;
    font-size: 25px;
    margin-right: 10px;
  }
  .for-btn-cad {
    display: inline-flex;
    align-content: center;
    align-items: center;
    margin-top: 25px;
  }
  .for-btn-cad p {
    font-size: 20px !important;
  }
  .for-btn-cad i {
    font-size: 20px;
    color: #7553e2;
    padding-left: 15px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .group {
    display: flex;
    margin-right: 30px;
  }
  .group > div {
    width: 330px;
    margin-top: 20px;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 25px;
    }
  }
  .group > div p {
    font-size: 18px;
    color: #ffffff;
  }
  @media (max-width: 768px) {
    .swiper-slide {
      padding: 0 20px !important;
    }
    .for-slide{
      margin-left:0px;
      margin-right:40px !important;
      margin-bottom:40px;
    }
  }
  @media (min-width:1008px) and (max-width:1070px) {
  .for-slide {
    height: 84%;
  }
  .group > div p {
    font-size: 18px;
  }
  h2 {
    font-size: 14px;
  }
  }
  @media (min-width:768px) and (max-width:1008px) {
  .for-slide {
    height: 82%;
  }
  .group > div p {
    font-size: 15px;
  }
  h2 {
    font-size: 13px;
    margin-top: 15px;
  }
  .group >div{
    margin-top:15px;
  }
  .title-for-service > div:first-child{
    margin-right:20px;
  }
    .title-for-service > div:last-child{
    margin-right:0px;
  }
  }
    @media (min-width:768px) and (max-width:923px) {
.for-btn-cad p{
  font-size: 16px !important;
}
h2{
  font-size: 14px;
}
.for-myProgress{
  margin-bottom:0px;
}
  }

}
</style>
