<template>
  <section class="testimonials my-5">

    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-3">{{ $t("home.testimonials.title") }}</h2>
        </div>
      </div>
    </div>

    <div @mouseenter="pauseSlider" @mouseleave="startSlider">
      <swiper v-if="testimonials" :options="swiper" ref="swiper">
        <swiper-slide
        v-for="(testimonial, index) in testimonials"
        :key="`${testimonial.name}-${index}`"
        class="swiper-slide"
        >
        <div class="embed-responsive embed-responsive-4by3 mb-3">
          <img class="embed-responsive-item" :src="testimonial.img" :alt="testimonial.name" />
        </div>
        <p>&ldquo;{{ testimonial.text }}&rdquo;</p>
        <span class="name">{{ testimonial.name }}</span>
        <span class="role">{{ testimonial.role }}</span>
      </swiper-slide>
    </swiper>
  </div>

</section>
</template>

<script>
export default {
  data() {
    return {
      swiper: {
        spaceBetween: 30,
        loop: true,
        speed: 4000,
        slidesPerView: 4,
        autoplay: {
          delay: 0
        },
        breakpoints: {
          768: {
            slidesPerView: 1
          }
        }
      },
      testimonials: null
    };
  },
  mounted() {
    this.fetchData();
    // document.querySelector(
    //   ".testimonials .swiper-wrapper"
    // ).style.transitionTimingFunction = "linear";
  },
  methods: {
    pauseSlider() {
      const { swiper } = this.$refs;
      swiper.swiper.autoplay.stop();
    },
    startSlider() {
      const { swiper } = this.$refs;
      swiper.swiper.autoplay.start();
    },
    async fetchData() {
      const testimonialsRes = await axios({
        method: "GET",
        url: "/api/v1/testimonies"
      });

      this.testimonials = testimonialsRes.data.data.map(testimonial => {
        return {
          img: `/${testimonial.source}`,
          name: testimonial.name,
          role: testimonial.description,
          text: testimonial.body
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  margin-bottom: 80px;
  h2 {
    color: #ffffff;
    font-size: 2em;
    @media screen and (max-width: 768px) {
      margin: 0 40px 40px 40px;
      img {
        border-radius: 10px;
        width: 100%;
        margin-bottom: 10px;
        object-fit: cover;
      }
    }
  }
  img {
    border-radius: 10px;
    width: 100%;
    margin-bottom: 10px;
    object-fit: cover;
  }
  p {
    font-size: 17px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .name {
    font-size: 17px;
    font-weight: 500;
    color: #ffffff;
  }
  .role {
    font-size: 17px;
    display: block;
    color: #666;
  }
  .swiper-wrapper {
    transition-timing-function: linear;
  }
  @media  (max-width: 768px) {
    .swiper-slide {
      padding: 0 40px !important;
    }
  }
}

</style>
