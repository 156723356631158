<template>
  <footer>
    <div class="container">
      <div class="contacts">
        <!--<div class="column col-lg-3 col-6 order-1">
        <div class="contact-inner">
          <a
            href="https://storage.googleapis.com/creators-static-1/documents/Termos_de_Uso_Creators.pdf"
            target="_blank"
            >{{ $t("home.footer.terms") }}</a
          >
          <a
            href="https://storage.googleapis.com/creators-static-1/documents/Politica_de_privacidade.pdf"
            target="_blank"
            >{{ $t("home.footer.privacy") }}</a
          >
          <a class="white" href="mailto:hello@creators.llc"
            >hello@creators.llc</a
          >
        </div>
      </div>-->
        <div class="column col-lg-6 col-12 order-lg-2 order-3 mt-4 mt-lg-0">
          <img src="https://storage.googleapis.com/creators-static-1/images/creators.png" alt="Creators Logo" class="creators" />
          <br />
          <h3>Powerful Influence<br>Marketing Platform</h3>
        </div>
        <div class="socials col-lg-3 col-6 order-2 order-lg-3">
          <a href="https://instagram.com/creators.llc" target="_blank">
            <img
              src="https://storage.googleapis.com/creators-static-1/images/socials/instagram.png"
              alt="Instagram Logo"
            />
          </a>
          <a href="https://linkedin.com/company/creators-llc" target="_blank">
            <img
              src="https://storage.googleapis.com/creators-static-1/images/socials/linkedin.png"
              alt="LinkedIn Logo"
            />
          </a>
          <a href="https://facebook.com/creators.llc" target="_blank">
            <img
              src="https://storage.googleapis.com/creators-static-1/images/socials/facebook.png"
              alt="Facebook Logo"
            />
          </a>
          <!-- <img src="https://storage.googleapis.com/creators-static-1/images/socials/twitter.png" alt="Twitter Logo" /> -->
        </div>
      </div>
      <div class="policy">
        <a href="https://creators.llc/documents/Termos_de_Uso_Creators.pdf" target="_blank">{{ $t("home.footer.terms") }}</a>
        <!-- <a href="https://creators.llc/documents/Politica_de_privacidade.pdf" target="_blank">{{ $t("home.footer.privacy") }}</a> -->
        <a :href="'mailto:\u0068\u0065\u006c\u006c\u006f\u0040\u0063\u0072\u0065\u0061\u0074\u006f\u0072\u0073\u002e\u006c\u006c\u0063'">{{ '\u0068\u0065\u006c\u006c\u006f\u0040\u0063\u0072\u0065\u0061\u0074\u006f\u0072\u0073\u002e\u006c\u006c\u0063' }}</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container-max {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
}
footer {
  border-top: 1px solid #444;
  padding: 95px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 40px 40px 0 40px;
  }
  .contacts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .column {
      padding: 0px;
      a {
        font-weight: 600;
        color: #7053d9;
        text-decoration: none;
        width: 100%;
        display: block;
        &.white {
          color: white;
        }
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
      span {
        font-weight: 600;
        margin: 3px 0;
        font-size: 16px;
        color: #ffffff;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
    .socials {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0px;
      a:not(:last-child) {
        margin-right: 10px;
      }
      img {
        height: 40px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 10px;
        }
        @media screen and (max-width: 768px) {
          max-height: 30px;
        }
      }
    }
  }
  .creators {
    width: 37%;
  }
  h3 {
    font-size: 17px;
    text-transform: uppercase;
    text-align: left;
    color: #ffffff;
    line-height: 1.3em;
    margin-top: 6px;
    @media screen and (max-width: 768px) {
      font-size: 1.5em;
      margin: 20px auto;
    }
    @media screen and (max-width: 425px) {
      font-size: 1em;
    }
  }
  .policy {
    margin-top: 30px;
    color: #8b90a0;
    font-size: 13px;
    a {
      margin-right: 20px;
    }
  }
}
@media screen and (max-width: 1020px) {
  footer .contacts {
    flex-direction: column-reverse;
  }
  footer .contacts .column {
    text-align: center;
  }
  footer h3 {
    text-align: center;
  }
  footer .contacts .socials {
    justify-content: center;
  }
  footer .policy {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
  }
  footer .policy a {
    text-align: center;
  }
  footer .creators {
    width: 65%;
  }
  footer .contacts .socials img {
    max-height: 35px;
  }
}
</style>
