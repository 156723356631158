import { render, staticRenderFns } from "./-Footer.vue?vue&type=template&id=d474c898&scoped=true&"
import script from "./-Footer.vue?vue&type=script&lang=js&"
export * from "./-Footer.vue?vue&type=script&lang=js&"
import style0 from "./-Footer.vue?vue&type=style&index=0&id=d474c898&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d474c898",
  null
  
)

export default component.exports