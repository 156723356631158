<template>
  <div class="videocard">

    <div class="container">
      <div class="row">
        <div class="col">
          <h2>{{ $t('home.cards.title') }}</h2>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row card-list">

        <flickity
          id="home-flickity-slider"
          class="w-100"
          ref="home-flickity-slider"
          :options="options"
        >

          <div class="col-lg-3 card-video" :class="'id-card-video-' + video.key" v-for="video in videos">
            <div class="myProgress">
              <div class="status" :class="'myBar-' + video.key"></div>
            </div>
            <video
              height="488"
              :id="'video-demo-' + video.key"
              class="desktop"
              preload="metadata"
              :src="video.src"
              :poster="video.poster"
              muted playsinline
            ></video>
            <div class="extra-info">
              <div class="social-display">
                <a :href="video.href" target="_blank">
                  <div class="social-info">
                    <i class="fab" :class="video.class"></i>
                    <p>{{ video.social }}</p>
                  </div>
                </a>
                <a :href="video.href" target="_blank">
                  <div class="copyright-info">
                    <i class="fa fa-copyright" aria-hidden="true"></i>
                    <p>{{ video.name }}</p>
                  </div>
                </a>
              </div>
              <div class="social-hover">
                <a href="/?openlogin=true">
                  <div class="next-info">
                    <p>Contrate agora</p>
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>

        </flickity>

      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Flickity from "flickity";
import "flickity/dist/flickity.css";
export default {

  data() {
    return {
      options: {
        cellSelector: ".card-video",
        cellAlign: "left",
        wrapAround: true,
        groupCells: 4,
        pageDots: false,
        resize: true,
        initialIndex: 0,
        imagesLoaded: true,
      },
      videos: [
        {
          key: 1,
          src: 'https://storage.googleapis.com/creators-static-1/videos/carla-diaz.mp4',
          poster: 'https://storage.googleapis.com/creators-static-1/videos/carla-diaz.jpg',
          name: 'Carla Diaz',
          href: 'https://www.instagram.com/carladiaz/',
          class: 'fa-instagram',
          social: 'Instagram',
        },
        // {
        //   key: 2,
        //   src: 'https://storage.googleapis.com/creators-static-1/videos/david-costa.mp4',
        //   poster: 'https://storage.googleapis.com/creators-static-1/videos/david-costa.jpg',
        //   name: 'David Costa',
        //   href: 'https://www.tiktok.com/@davidcstt_',
        //   class: 'fa-tiktok',
        //   social: 'TikTok',
        // },
        {
          key: 2,
          src: 'https://storage.googleapis.com/creators-static-1/videos/fizemos-um-role.mp4',
          poster: 'https://storage.googleapis.com/creators-static-1/videos/fizemos-um-role.jpg',
          name: 'Fizemos um Rolê',
          href: 'https://www.instagram.com/fizemosumrole/',
          class: 'fa-instagram',
          social: 'Instagram',
        },
        {
          key: 3,
          src: 'https://storage.googleapis.com/creators-static-1/videos/nataly-neri.mp4',
          poster: 'https://storage.googleapis.com/creators-static-1/videos/nataly-neri.jpg',
          name: 'Nátaly Neri',
          href: 'https://www.instagram.com/natalyneri/',
          class: 'fa-instagram',
          social: 'Instagram',
        },
        {
          key: 4,
          src: 'https://storage.googleapis.com/creators-static-1/videos/jeska-grecco.mp4',
          poster: 'https://storage.googleapis.com/creators-static-1/videos/jeska-grecco.jpg',
          name: 'Jeska Grecco',
          href: 'https://www.instagram.com/jeskagrecco/',
          class: 'fa-instagram',
          social: 'Instagram',
        },
        {
          key: 5,
          src: 'https://storage.googleapis.com/creators-static-1/videos/carol-wang.mp4',
          poster: 'https://storage.googleapis.com/creators-static-1/videos/carol-wang.jpg?v2',
          name: 'Carol Wang',
          href: 'https://www.tiktok.com/@carolwangart',
          class: 'fa-tiktok',
          social: 'TikTok',
        },
        {
          key: 6,
          src: 'https://storage.googleapis.com/creators-static-1/videos/cris.mp4',
          poster: 'https://storage.googleapis.com/creators-static-1/videos/cris.jpg',
          name: 'Cris Guerra',
          href: 'https://www.instagram.com/eucrisguerra/',
          class: 'fa-instagram',
          social: 'Instagram',
        },
        {
          key: 7,
          src: 'https://storage.googleapis.com/creators-static-1/videos/joicy.mp4',
          poster: 'https://storage.googleapis.com/creators-static-1/videos/joicy.jpg',
          name: 'Joicy Eleiny',
          href: 'https://www.tiktok.com/@soutipo4',
          class: 'fa-tiktok',
          social: 'TikTok',
        },
        {
          key: 8,
          src: 'https://storage.googleapis.com/creators-static-1/videos/tchulim.mp4',
          poster: 'https://storage.googleapis.com/creators-static-1/videos/tchulim.jpg',
          name: 'Tchulim',
          href: 'https://www.instagram.com/tchulim/',
          class: 'fa-instagram',
          social: 'Instagram',
        },
        {
          key: 9,
          src: 'https://storage.googleapis.com/creators-static-1/videos/jupdobairro.mp4',
          poster: 'https://storage.googleapis.com/creators-static-1/videos/jupdobairro.jpg',
          name: 'Jup do Bairro',
          href: 'https://www.instagram.com/jupdobairro/',
          class: 'fa-instagram',
          social: 'Instagram',
        },
      ],
    }
  },

  beforeMount() {
    if (window.innerWidth < 768) {
      this.options.groupCells = 1;
    }
  },

  mounted() {

    setTimeout((e) => {
      this.$refs["home-flickity-slider"] && this.$refs["home-flickity-slider"].resize();
    }, 300);

    setTimeout((e) => {
      this.$refs["home-flickity-slider"] && this.$refs["home-flickity-slider"].resize();
    }, 600);

    var videoOrder = 1;
    var width = 1;
    var color = "#FFF";
    
    requestAnimationFrame(frame);

    function frame() {
      if (width >= 100) {
        //clearInterval(id);
        var video = document.getElementById(`video-demo-${videoOrder}`);
        video.pause();
        video.load();
        width = 0;
        color = "inherit";
        $(`.myBar-${videoOrder}`).css("width", width);
        videoOrder++;
        if (videoOrder > 9) videoOrder = 1;
        video = document.getElementById(`video-demo-${videoOrder}`);
        $(`.myBar-${videoOrder}`).css("width", width);
        video.play();
      } else {
        width += 0.2;
        $(`.myBar-${videoOrder}`).css("width", width + "%");
      }

      requestAnimationFrame(frame)

    }

    var video = document.getElementById(`video-demo-${videoOrder}`);
    video.play();

  },

  methods: {
    //
  },

  watch: {
   //
  },

};
</script>
<style lang="scss" scoped>
.videocard {
  .card-list .card-video {
    position: relative;
  }
  .card-list .card-video img {
    width: 100%;
    height: auto;
  }
  .card-list .card-video:hover .social-hover {
    visibility: visible;
    height: 100%;
    transform: translateY(0%);
    transition: all 0.6s ease-in-out;
    -webkit-transform: translateY(0%);
    overflow: hidden;
  }
  .card-list .card-video:hover video {
    opacity: 0.6;
  }
  .card-list video {
    object-fit: cover;
    border-radius: 16px;
  }
  .card-list .card-video:hover .extra-info {
    display: block;
  }
  video {
    width: 100%;
  }
  h2 {
    font-size: 26px;
    margin-bottom: 48px;
    line-height: normal;
    color: #ffffff;
  }
  .myProgress {
    width: 100%;
    background-color: #3e465f;
    margin-bottom: 20px;
  }
  .status {
    width: 0%;
    height: 3px;
    background-color: #ffffff;
  }
  .social-hover {
    visibility: hidden;
    height: 0px;
    transition: all 0.3s ease-in-out;
    transform: translateY(30%);
    -webkit-transform: translateY(30%);
    overflow: hidden;
    border-radius: 0px 0 16px 16px;
  }
  .extra-info {
    position: absolute;
    bottom: 6px;
    left: -1px;
    right: 0px;
    width: 101%;
    transition: all 0.6s ease-in-out;
    padding-top: 200px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 0, 0, 0),
      #010001 90%
    );
    border-radius: 0px 0 16px 16px;
  }
  .social-hover > div.com-info {
    display: flex;
    background: #1e1e1e;
    border-radius: 142px;
    margin-bottom: 5px;
    padding: 5px 15px;
    margin-left: 7%;
    margin-right: 7%;
    width: 11rem;
  }
  i.fab.fa-tiktok {
    color: #ffffff !important;
    text-shadow: 1px 1px #3d1254;
  }
  i.fab.fa-instagram {
    color: #ffffff !important;
    border-radius: 5px;
  }
  .social-hover > div.com-info p {
    color: #8b90a0;
    font-size: 16px;
  }
  .social-hover > div > p:first-child {
    color: #a1a4b1;
    font-size: 16px;
    font-weight: 600;
  }
  p.weekp{
    color:#8b90a0 !important;
  }
  .social-hover > div > p:last-child {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    text-transform: uppercase;
  }
  .social-display .social-info {
    display: flex;
    align-items: center;
    margin-left: 7%;
    margin-right: 7%;
    transition: all 0.3s ease-in-out;
  }
  .social-display p {
    color: #fff;
    font-size: 16px;
    margin-left: 10px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
  }
  .social-display .social-info i {
    color: #ff0000;
    font-size: 20px;
  }
  .social-display {
    transition: all 0.6s ease-in-out;
  }
  .social-display .copyright-info {
    display: flex;
    align-items: center;
    padding: 10px 0px 20px;
    margin-left: 7%;
    margin-right: 7%;
  }
  .social-display .copyright-info i {
    color: white;
    font-size: 20px;
  }
  .extra-info .special-info {
    display: flex;
    align-items: center;
    padding: 20px 7% 20px;
  }
  .extra-info .special-info > div {
    display: flex;
    align-items: center;
  }
  .extra-info .special-info > div p {
    color: #74e7d0;
    padding: 0 3px;
  }
  .extra-info .special-info i {
    color: #74e7d0;
    font-size: 13px;
  }
  .social-hover > p {
    color: #8b90a0;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 15px;
    text-transform: uppercase;
    margin-left: 7%;
    margin-right: 7%;
  }
  .social-hover .next-info {
    display: flex;
    align-items: center;
    background-image: linear-gradient(
      90.09deg,
      #3e465f -56.06%,
      rgba(62, 70, 95, 0) 99.91%
    );
    padding: 15px 34px;
  }
  .next-info p {
    font-size: 16px;
    color: #d3d4d8;
    font-weight: 600;
  }
  .social-hover .next-info i {
    padding-left: 15px;
    color: #8b90a0;
  }
}
@media screen and (max-width: 1020px) {
  .videocard {
    padding: 40px;
  }
  .videocard > p:first-child {
    font-size: 20px;
  }
}
.card-list {
  transition: transform 0.4s ease-in-out;
}
@media (max-width: 390px) {
  .video-card.card-list {
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 1500px;
  }
  .card-list.card-video-mobile1 {
    transform: translateX(0);
    width: 1100px;
  }
  .card-list.card-video-mobile2 {
    transform: translateX(-250px);
    width: 1100px;
  }
  .card-list.card-video-mobile3 {
    transform: translateX(-500px);
    width: 1100px;
  }
  .card-list.card-video-mobile4 {
    transform: translateX(-790px);
    width: 1100px;
  }
  .videocard .card-list > div {
    width: 250px;
  }
}
@media (min-width: 390px) and (max-width: 480px) {
  .video-card.card-list {
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 1500px;
  }
  .card-list.card-video-mobile1 {
    transform: translateX(0);
    width: 1100px;
  }
  .card-list.card-video-mobile2 {
    transform: translateX(-250px);
    width: 1100px;
  }
  .card-list.card-video-mobile3 {
    transform: translateX(-500px);
    width: 1100px;
  }
  .card-list.card-video-mobile4 {
    transform: translateX(-750px);
    width: 1100px;
  }
  .videocard .card-list > div {
    width: 250px;
  }
}
@media (min-width: 480px) and (max-width: 600px) {
  .video-card.card-list {
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 1500px;
  }
  .card-list {
    transition: transform 0.4s ease-in-out;
  }
  .card-list.card-video-mobile1 {
    transform: translateX(0);
    width: 1100px;
  }
  .card-list.card-video-mobile2 {
    transform: translateX(-250px);
    width: 1100px;
  }
  .card-list.card-video-mobile3 {
    transform: translateX(-500px);
    width: 1100px;
  }
  .card-list.card-video-mobile4 {
    transform: translateX(-550px);
    width: 1100px;
  }
  .videocard .card-list > div {
    width: 250px;
  }
}
@media (min-width: 600px) and (max-width: 768px) {
  .video-card.card-list {
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 1500px;
  }
  .card-list {
    transition: transform 0.4s ease-in-out;
  }
  .card-list.card-video-mobile1 {
    transform: translateX(0);
    width: 1100px;
  }
  .card-list.card-video-mobile2 {
    transform: translateX(-150px);
    width: 1100px;
  }
  .card-list.card-video-mobile3 {
    transform: translateX(-350px);
    width: 1100px;
  }
  .card-list.card-video-mobile4 {
    transform: translateX(-550px);
    width: 1100px;
  }
  .videocard .card-list > div {
    width: 250px;
  }
}
</style>
