<template>
  <section class="position-relative z-index-2" id="section-cards">
    <ul>
      <li
      v-for="(image, index) in images.slice(0, 18)"
      :key="index"
      ref="images"
      :style="{ backgroundImage: `url(${image})` }"
      />
    </ul>
  </section>
</template>

<script>
import { TweenMax, TimelineMax } from "gsap";

export default {
  data() {
    return {
      nextImage: null,
      lastImages: [],
      images: [
        "https://storage.googleapis.com/creators-static-1/images/cards/ana_batista-UI-design-creators.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/ana-batista-mobile-app-UI-design.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/motion-estudio-creators.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/3d-lllustrator-art-creator-freelancer-creators-05.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/3d-lllustrator-art-creator-freelancer-creators-06.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/absolut-freelancer-3d-illustrator-freelancer-creator-creators-02.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/creators-creator-freelancer-illustrator-designer-nosotros-2.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/creators-creator-freelancer-illustrator-designer-nosotros-3.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/creators-creator-freelancer-illustrator-designer-nosotros-4.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/creators-creator-freelancer-illustrator-designer-nosotros-5.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/creators-creator-freelancer-illustrator-designer-nosotros-6.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/creators-creator-freelancer-illustrator-designer-nosotros-7.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/creators-creator-freelancer-illustrator-designer-nosotros-8.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/creators-creator-freelancer-illustrator-designer-nosotros-9.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/creators-creator-freelancer-illustrator-designer-nosotros-10.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/creators-creator-freelancer-illustrator-designer-nosotros1.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/criolo-creators.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/fotografo-malchique-creators.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/ilustrador-creators.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/ilustradora-illustrator-creators.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/jorge-romanos-visual-designer-melissa-creators-creator-freelancer_02.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/logotipo-designer-creators.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/loic-creators.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/luiza-brasil-creators.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/raisa-almeida-efemeras-nike-creators-design.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/spartakus-creators.jpg",
        "https://storage.googleapis.com/creators-static-1/images/cards/vitor-dicastro-creators.jpg",
      ],
      interval: null
    };
  },
  mounted() {
    this.images.sort(() => Math.random() - 0.5);
    this.printRandomically();

    if (window.performance) {
      this.showRandomically();
    }
    if (performance.navigation.type == 1) {
      this.showRandomically();
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    printRandomically() {
      const { images } = this.$refs;
      const arr = [...images];
      const shuffleArray = arr => arr.sort(() => Math.random() - 0.5);

      TweenMax.staggerFromTo(
        shuffleArray(arr),
        0.3,
        {
          opacity: 0,
          y: -10
        },
        {
          opacity: 1,
          y: 0
        },
        0.1
      );
    },
    generateNewNumber() {
      this.nextImage = Math.floor(Math.random() * this.images.length);
      if (this.lastImages.includes(this.nextImage)) {
        this.generateNewNumber();
      }
    },
    changeRandomically() {
      this.interval = setInterval(() => {
        const imageContainer = Math.floor(Math.random() * 23);
        this.nextImage = Math.floor(Math.random() * this.images.length);
        if (this.lastImages.includes(this.nextImage)) {
          this.generateNewNumber();
        }
        // this.changeImage(imageContainer, this.nextImage);
      }, 5000);
    },
    showRandomically() {
      const imageContainer = Math.floor(Math.random() * 23);
      this.nextImage = Math.floor(Math.random() * this.images.length);
      if (this.lastImages.includes(this.nextImage)) {
        this.generateNewNumber();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -2%;
}
ul {
  width: 100%;
  display: grid;
  grid-auto-rows: 1fr;
  grid-auto-columns: 15%;
  list-style: none;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 1.5%;
  align-content: center;
  padding: 0;
  @media screen and (max-width: 1000px) {
    grid-gap: 15px;
    min-width: 990px;
  }
  li {
    width: 100%;
    min-width: 150px;
    min-height: 175px;
    padding-bottom: 125%;
    display: flex;
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    opacity: 0;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;

    &:nth-child(-n + 2) {
      grid-column: 1;
      transform: translateY(-50%) !important;
      // &:hover {
      //   transform: translateY(-50%) scale(1.1) !important;
      // }
    }
    &:nth-child(n + 3):nth-child(-n + 4) {
      grid-column: 2;
      // &:hover {
      //   transform: scale(1.1) !important;
      // }
    }
    &:nth-child(n + 5):nth-child(-n + 7) {
      grid-column: 3;
      transform: translateY(-50%) !important;
      // &:hover {
      //   transform: translateY(-50%) scale(1.1) !important;
      // }
    }
    &:nth-child(n + 8):nth-child(-n + 11) {
      grid-column: 4;
      // &:hover {
      //   transform: scale(1.1) !important;
      // }
    }
    &:nth-child(n + 12):nth-child(-n + 15) {
      grid-column: 5;
      transform: translateY(50%) !important;
      // &:hover {
      //   transform: translateY(-50%) scale(1.1) !important;
      // }
    }
    &:nth-child(n + 16):nth-child(-n + 18) {
      grid-column: 6;
      // &:hover {
      //   transform: scale(1.1) !important;
      // }
    }

    &:nth-child(1) {
      grid-row: 1 / 3;
      align-self: end;
    }
    &:nth-child(4) {
      grid-row: 1 / 3;
      align-self: end;
    }
    &:nth-child(7) {
      grid-row: 1 / 3;
      align-self: end;
    }
    &:nth-child(21) {
      grid-row: 1 / 3;
      align-self: end;
    }
  }
}
</style>
