<template>
  <section class="container">
    <h2>{{ $t("home.video.title") }}</h2>
    <div class="video-container">
      <div class="thumb" @click="startVideo">
        <video
          poster="https://storage.googleapis.com/creators-static-1/videos/cover.jpg"
          class="background"
          src="https://storage.googleapis.com/creators-static-1/videos/CREATORS_MANIFESTO_05.mp4"
          muted
          loop
        />
        <!-- <img class="background" src="https://storage.googleapis.com/creators-static-1/images/video/background.png" alt="Background Image"> -->
        <img
          class="thumb-icon"
          src="https://storage.googleapis.com/creators-static-1/images/video/play.png"
          alt="Thumb Play Button"
        />
      </div>
    </div>
    <video
      class="video-full"
      ref="video"
      :src="source"
      controls
      @pause="pauseVideo"
      @play="$gtag.event('video-manifesto')"
    />
  </section>
</template>

<script>
import { TweenMax } from "gsap";

export default {
  data() {
    return {
      source:
        "https://storage.googleapis.com/creators-static-1/videos/CREATORS_MANIFESTO_05-mob.mp4",
    };
  },

  mounted() {
    if (window.innerWidth > 768) {
      this.source =
        "https://storage.googleapis.com/creators-static-1/videos/CREATORS_MANIFESTO_05.mp4";
    }

    // $("video.background").each(function (i, obj) {
    //   $(this).on("mouseover", function () {
    //     hoverVideo(i);
    //   });
    //   $(this).on("mouseout", function () {
    //     hideVideo(i);
    //   });
    // });

    function hoverVideo(i) {
      // $("video.background")[i].play();
    }

    function hideVideo(i) {
      // $('.thevideo')[i].currentTime = 0;
      // $("video.background")[i].pause();
    }
  },
  methods: {
    startVideo() {
      // $('video').css({"transform":"rotate(90deg) matrix(1, 0, 0, 1, 0, 0) scale(1.8)"});

      const { video } = this.$refs;

      TweenMax.fromTo(
        video,
        0.5,
        {
          opacity: 0,
          y: "100vh",
        },
        {
          opacity: 1,
          y: 0,
        }
      );
      video.play();
    },
    pauseVideo() {
      const { video } = this.$refs;

      TweenMax.fromTo(
        video,
        0.5,
        {
          opacity: 1,
          y: 0,
        },
        {
          opacity: 0,
          y: "100vh",
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  @media (max-width: 768px) {
    display: none;
  }
  h2 {
    display: inline-block;
    color: #ffffff;
    margin: 20px 0;
    font-size: 2em;
  }
  > video {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 10;
    opacity: 0;
    transform: translateY(100vh);
  }
  @media screen and (max-width: 1000px) {
    > video {
      object-fit: contain;
    }
  }
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    .thumb {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: grid;
      grid-template-areas: "single";
      align-items: center;
      justify-items: center;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0;
      }
      &:hover {
        .thumb-icon {
          transform: scale(1.1);
        }
      }
      .background {
        grid-area: single;
        width: 100%;
        height: 100%;
        /* opacity: 0.5; */
      }
      .thumb-icon {
        grid-area: single;
        width: 10%;
        transition: transform 0.2s ease-in-out;
        cursor: pointer;
        z-index: 1;
      }
    }
  }
  .video-full {
    background: black;
  }
}
@media screen and (max-width: 1020px) {
  section {
    padding: 40px;
  }
}
@media (max-width: 768px) {
  section {
    padding: 20px;
  }
}
</style>
