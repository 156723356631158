
import { required, email, minLength } from "vuelidate/lib/validators";
import { TweenMax } from "gsap";

export default {
  name: "BeCreator",
  data() {
    return {
      TOKEN: "",
      language: "pt",
      requestResponse: {},
      email: "",
      password: "",
      code: "",
      code_show: false,
      cnpj: "",
      name: "",
      lastName: "",
      phone: "+55",
      check: false,
      value: "",
      group: "",
      habilitie: "",
      habilitieInput: "",
      complementary: [],
      speciality: {
        id: null
      },
      categories: [],
      site: "",
      linkedin: "",
      portfolio: "",
      instagram: "",
      behance: "",
      vimeo: "",
      youtube: "",
      social: {
        slots: [
          {item: 0, value: '', visible: true,},
          {item: 0, value: '', visible: false,},
          {item: 0, value: '', visible: false,},
          {item: 0, value: '', visible: false,},
          {item: 0, value: '', visible: false,},
          {item: 0, value: '', visible: false,},
          {item: 0, value: '', visible: false,},
          {item: 0, value: '', visible: false,},
          {item: 0, value: '', visible: false,},
        ],
        networks: [
          {name: 'Escolha uma rede social', slug: '', value: '', mask: '', error: false,},
          {name: 'Behance', slug: 'behance', value: '', mask: 'https://www.behance.net/', error: false,},
          {name: 'Facebook', slug: 'facebook', value: '', mask: 'https://www.facebook.com/', error: false,},
          {name: 'Instagram', slug: 'instagram', value: '', mask: 'https://www.instagram.com/', error: false,},
          {name: 'LinkedIn', slug: 'linkedin', value: '', mask: 'https://www.linkedin.com/in/', error: false,},
          {name: 'Site pessoal', slug: 'site', value: '', mask: 'https://', error: false,},
          {name: 'TikTok', slug: 'tiktok', value: '',  mask: 'https://www.tiktok.com/@', error: false,},
          {name: 'Twitch', slug: 'twitch', value: '', mask: 'https://www.twitch.tv/', error: false,},
          {name: 'Twitter', slug: 'twitter', value: '', mask: 'https://twitter.com/', error: false,},
          {name: 'Vimeo', slug: 'vimeo', value: '', mask: 'https://vimeo.com/', error: false,},
          {name: 'Youtube', slug: 'youtube', value: '', mask: 'https://www.youtube.com/c/', error: false,},
        ],
      },
      step: 1,
      passwordState: "password",
      checkError: false,
      search: "",
      sync: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
    name: {
      required,
    },
    phone: {
      required,
      minLength: minLength(15),
    },
    code: {},
    speciality: {
      required,
    },
    complementary: {
      required,
    },
    cnpj: {},
    linkedin: {},
    site: {},
    instagram: {},
    behance: {},
    vimeo: {},
    youtube: {},
  },
  async mounted() {

    let response, top = {};

    response = await axios({
      method: "GET",
      url: "/api/v1/top/specialities",
    });
    top.pt = top.en = top.es = "Mais usadas";
    top.image = "https://storage.googleapis.com/creators-static-1/images/categories/graphic-design.png";
    top.specialities = response.data.data;

    response = await axios({
      method: "GET",
      url: "/api/v1/categories",
    });
    response.data.data.unshift(top);
    for (let i = 0; i < response.data.data.length; i++) {
      let category = response.data.data[i];
      category.show = false;
      for (let j = 0; j < category.specialities.length; j++) {
        let speciality = category.specialities[j];
        speciality.selected = false;
      }
    }
    this.categories = response.data.data;

  },
  methods: {

    authenticate(provider) {
      var _this = this;
      if (_this.$auth.isAuthenticated()) {
        _this.$auth.logout();
      }
      this.$auth
        .authenticate(provider)
        .then((response) => {
          console.log("authenticate->then");
          axios
            .post("/auth/" + provider + "/callback", {
              access_token: response.data.access_token,
            })
            .then((response) => {
              if (response.data.data) {
                _this.success(response.data);
              }
              if (response.data.error) {
                _this.errors.fail = true;
              }
            });
        })
        .catch((err) => {
          console.log({ err: err });
        });
    },

    success(data) {
      // save token for future use
      this.$store.commit("setToken", data.data.access_token);
      this.TOKEN = data.data.access_token;
      // save me for future use
      this.me = data.data.user;
      this.$store.commit("setMe", data.data.user);
      this.step++;
    },

    changePass() {
      if (this.passwordState === "password") {
        this.passwordState = "text";
      } else {
        this.passwordState = "password";
      }
    },
    remove(name) {
      this.complementary = this.complementary.filter((e) => e !== name);
    },
    setError(field) {
      this[field] = "";
    },
    handleChange(field, value) {
      switch (typeof this[field]) {
        case "object":
          if (!this[field].find((e) => e === value)) {
            if (this.complementary.length < 5) {
              this.complementary.push(value);
              this.complementary = this.complementary.filter((e) => e !== "");
            }
          }
          break;
        default:
          this[field] = value;
          break;
      }
      this.$v[field] &&
        this.$v[field].$params.required &&
        this.$v[field].$touch();
    },

    chooseSpeciality(s) {
      // já está na lista
      if (s.id == this.speciality.id) {
        this.speciality = {id: null};
        this.complementary = [];
      } else if (this.complementary.some(elem => elem.id == s.id)) {
        this.complementary = this.complementary.filter(elem => elem.id != s.id)
      } else {
        if (!this.speciality.id) {
          this.speciality = s;
        } else {
          if (this.complementary.length < 4) {
            this.complementary.push(s);
          }
        }
      }
    },

    handleSpecialityChange(field, value) {
      let index = this.habilities
        .map(function (e) {
          return e.pt;
        })
        .indexOf(value);
      // faz parte da lista de habilidades
      if (index > -1) {
        this[field] = "";
        // não limpa o campo dentro do componente só alterando a variavel
        // $(".habilidades.custom-input input").val("");
        this.habilitie = value;
        this.$v[field] &&
          this.$v[field].$params.required &&
          this.$v[field].$touch();
      }
    },

    async submitForm() {

      this.sync = true;

      if (this.$auth.isAuthenticated()) {
        this.$auth.logout();
      }

      if (this.step === 1) {
        // submit step 1 - e-mail e senha

        this.$v.email.$touch();
        this.$v.password.$touch();
        if (!this.check) {
          this.checkError = true;
        }
        if (
          !this.$v.email.$invalid &&
          !this.$v.password.$invalid &&
          this.check
        ) {
          try {
            this.requestResponse = await axios({
              method: "POST",
              header: {
                "Content-Type": "aplication/json",
              },
              url: "/api/v1/users",
              data: {
                email: this.email,
                password: this.password,
                origin: "creators",
              },
            });

            this.sync = false;

            if (this.requestResponse.data.error) {
              console.log(this.requestResponse.data.error);
              if (this.requestResponse.data.error.email)
                alert("E-mail já cadastrado. Caso você não lembre a senha, acesse o link de esqueci minha senha na tela de login.");
              return;
            } else {
              this.$store.commit("setToken", this.requestResponse.data.data.access_token);
              this.$store.commit("setMe", this.requestResponse.data.data.user);
              this.TOKEN = this.requestResponse.data.data.access_token;
            }
          } catch (err) {}
          this.step++;
          setTimeout((event) => $("#input-name").focus(), 600);
        } else {
          this.sync = false;
        }

      } else if (this.step === 2) {
        // submit step 2 - tipo de talento

        this.$v.name.$touch();
        this.$v.phone.$touch();

        if (
          !this.$v.name.$invalid &&
          !this.$v.phone.$invalid
        ) {

          try {
            const data = {
              name: this.name,
              phone: this.phone,
              curator_code: this.code,
            };

            axios
              .put(`/api/v1/users/${this.$store.state.me.id}`, data, {
                headers: {
                  Authorization: "Bearer " + this.TOKEN,
                },
              })
              .then((response) => {
                this.sync = false;
                if (response.data.data) {
                  this.step++;
                  if (this.group == "influ") {
                    this.categories = this.categories.filter((category) => (category.id == 10 || category.id == 11 || category.id == 12) );
                  }
                }
                if (response.data.error) {
                  this.errors.fail = true;
                }
              });
          } catch (err) {
            this.sync = false;
            console.log(err);
          }

      } else {
        this.sync = false;
      }


      } else if (this.step === 3) {
        // submit step 3 - TAGS

        try {

          const data = {
            mainspeciality: this.speciality.id,
            specialities: this.complementary.map(i => i.id).join("; "),
          };

          if (this.group == "influ") {
            data.specialities = data.specialities + "; " + 98;
          }

          axios
            .put(`/api/v1/users/${this.$store.state.me.id}`, data, {
              headers: {
                Authorization: "Bearer " + this.TOKEN,
              },
            })
            .then((response) => {
              this.sync = false;
              console.log(response);
              if (response.data.data) {
                this.step++;
              }
              if (response.data.error) {
                this.errors.fail = true;
              }
            });
        } catch (err) {
          this.sync = false;
          console.log(err);
        }

      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          try {
            const data = {
              behance: this.social.networks[1].value,
              facebook: this.social.networks[2].value,
              instagram: this.social.networks[3].value,
              linkedin: this.social.networks[4].value,
              site: this.social.networks[5].value,
              tiktok: this.social.networks[6].value,
              twitch: this.social.networks[7].value,
              twitter: this.social.networks[8].value,
              vimeo: this.social.networks[9].value,
              youtube: this.social.networks[10].value,
            };

            axios
              .put(`/api/v1/users/${this.$store.state.me.id}`, data, {
                headers: {
                  Authorization: "Bearer " + this.TOKEN,
                },
              })
              .then((response) => {
                this.sync = false;
                if (response.data.data) {
                  this.$emit("close-modal", "login");
                  $("html").css("overflow", "auto");

                  // open briefing
                  if (this.$route.query.briefing) {
                    window.location = "/dashboard/professional/briefing/" + this.$route.query.briefing;
                  } else {
                    window.location = "/dashboard/professional";
                  }
                }
                if (response.data.error) {
                  this.errors.fail = true;
                }
              });
          } catch (err) {
            console.log(err);
          }
        } else {
          this.sync = false;
        }
      }
    },

    addSocialSlot() {
      for (var i = 0; i < this.social.slots.length; i++) {
        if (!this.social.slots[i].visible) {
          this.social.slots[i].visible = true;
          break;
        }
      }
    },

  },

  computed: {
    filteredCategories() {
      if (this.search && this.search != "") {
        return this.categories.map(category => {
          category.filteredSpecialities = category.specialities
          .filter(speciality => {
            return speciality.pt.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
          })
          return category;
        });

      } else {
        for (var i = 0; i < this.categories.length; i++) {
          this.categories[i].filteredSpecialities = this.categories[i].specialities;
        }
        return this.categories;
      }
    },

    usedNetworks() {
      let list = [];
      for (var i = 0; i < this.social.slots.length; i++) {
        if (this.social.slots[i].item != 0) {
          list.push(this.social.slots[i].item);
        }
      }
      return list;
    },

  }

};
