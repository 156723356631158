<template>
  <section>

    <div class="background position-relative z-index-2">
        <div class="container">
          <div class="banner-text" ref="quote">
            <h3 class="title-desktop">{{ $t("home.hero.title") }}</h3>
            <h3 class="title-mobile">{{ $t("home.hero.title_mobile") }}</h3>
            <hr />
            <p class="mb-2" v-html="$t('home.hero.subtitle')"></p>
            <div class="btn btn-primary w-auto banner-button-left mr-2" v-if="!TOKEN" v-on:click="handleModal('login')">
              {{ $t("home.hero.btn2") }}
            </div>
            <a href="https://creators.llc/agendar-demo/" class="btn btn-primary w-auto mr-2" target="_blank">{{ $t("home.hero.btn1") }}</a>
            <!-- <div class="btn btn-primary w-auto" v-if="!TOKEN" v-on:click="goFullScreen('video-demo')">
            {{ $t("home.hero.btn3") }}
          </div> -->
        </div>
      </div>
    </div>

    <div class="main-banner" ref="banner">
      <video playsinline autoplay muted loop class="main-banner-video">
        <source src="https://storage.googleapis.com/creators-static-1/images/home/banners/63_1637863584.mp4" type="video/mp4">
      </video>
    </div>

  </section>
</template>
<script>
import { TimelineMax } from "gsap";
import Clients from "./-Clients";
import axios from "axios";
import $ from "jquery";
export default {
  components: {
    Clients,
  },
  data() {
    return {
      TOKEN: this.$store.state.token,
      images: [],
      interval: null,
      random: null,
    };
  },
  mounted() {
    const { quote, author } = this.$refs;
    const tl = new TimelineMax();
    this.fetchData();
    tl.fromTo(
      quote,
      1.5,
      {
        opacity: 0,
        x: -50,
      },
      {
        opacity: 1,
        x: 0,
      }
    ).fromTo(
      author,
      0.5,
      {
        opacity: 0,
        y: 30,
      },
      {
        opacity: 1,
        y: 0,
      }
    );
    setTimeout(() => this.changeImage(), [10000]);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    scrollPage() {
      $("a").on("click", function (event) {
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
          // Prevent default anchor click behavior
          event.preventDefault();

          // Store hash
          var hash = this.hash;

          // Using jQuery's animate() method to add smooth page scroll
          // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
          $("html, body").animate(
            {
              scrollTop: $(hash).offset().top,
            },
            800,
            function () {
              // // Add hash (#) to URL when done scrolling (default click behavior)
              // window.location.hash = hash;
            }
          );
        } // End if
      });
    },
    goFullScreen(id) {
      let element = document.getElementById(id);

      if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        element.play();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      }
    },
    handleModal(type) {
      this.$gtag.event("free-trial");
      this.$emit("open-modal", type);
    },
    changeImage() {
      this.interval = setInterval(() => {
        const { banner } = this.$refs;
        const tl = new TimelineMax();
        tl.to(banner, 0.5, {
          opacity: 0,
        })
          .call(() => {
            if (this.random + 1 == this.images.length) {
              this.random = 0;
            } else {
              this.random++;
            }
          })
          .set(banner, {
            backgroundImage: `url(${this.images[this.random].src})`,
          })
          .to(banner, 0.5, {
            opacity: 1,
            delay: 0.1,
          });
      }, 6000);
    },
    async fetchData() {
      const banners = await axios({
        method: "GET",
        url: "/api/v1/banners",
      });
      banners.data.data = this.shuffle(banners.data.data);
      this.images = banners.data.data.map((banner) => ({
        src: `/${banner.source}`,
        author: banner.author,
        description: banner.description,
      }));
      this.random = Math.floor(Math.random() * this.images.length);
      // novo sorteio caso o primeiro banner seja video (lento)
      let current = this.images[this.random].src;
      if (current.includes("mp4") || current.includes("gif"))
        this.random = Math.floor(Math.random() * this.images.length);
      // novo sorteio caso o primeiro banner seja video (lento), segunda iteração
      current = this.images[this.random].src;
      if (current.includes("mp4") || current.includes("gif"))
        this.random = Math.floor(Math.random() * this.images.length);
      // abortar (caso só tenha video e gif na lista)
    },

    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;
      while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }
      return array;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-desktop {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.title-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

section {
  .main-banner {
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    .main-banner-video {
      position: absolute;
      left: 50%;
      top: 50vh;
      margin: -50vh 0 0 -50%;
      min-width: 100vw;
      min-height: 100vh;
    }
  }

  @-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
    60% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
  @keyframes bounce {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(15px);
      transform: translateY(15px);
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
  .scroll-down {
    -webkit-animation: bounce 2s 10;
    animation: bounce 2s infinite;
  }
  .margin-negative-home {
    margin-top: -100px;
  }
  .main-banner {
    .mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
    }
  }
  .background {
    align-items: center;
    display: grid;
    grid-template-rows: 85% 15%;
    height: auto;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0) 75%, #111111 90%);
    background-position: center;
    height: 100vh;
    @media screen and (max-width: 1020px) {
      padding: 40px;
    }
    @media screen and (max-width: 768px) {
      padding: 20px;
      display:block;
    }
    background-size: cover;
    @media screen and (min-width: 768px) {
      grid-template-rows: 90% 10%;
    }
  }
  .banner-text {
    line-height: 32px;
    word-spacing: 1px;
    color: #ffffff;
    font-weight: 600;
    opacity: 0;
    width: 60%;
    max-width: 500px;
    padding-top: 200px;
    padding-bottom: 120px;
    @media screen and (max-width: 1020px) {
      padding-top: 120px;
    }
    h3 {
      font-size: 46px;
      line-height: 55px;
      color: white;
    }
    p {
      font-size: 1.3em;
      line-height: 1.5em;
      color: white;
      margin-bottom: 32px !important;
    }
    hr {
      border-color: white;
      margin: 32px 0px;
      width: 55%;
    }
    @media screen and (max-width: 1020px) {
      width: 100%;
      h3 {
        font-size: 26px;
        line-height: 30px;
      }
      p {
        font-size: 1.2em;
        font-weight: 100;
      }
    }
  }
  .banner-author {
    font-size: 1.1em;
    text-size-adjust: 100%;
    font-weight: 500;
    color: #ffffff;
    opacity: 0;
    @media screen and (max-width: 380px) {
      font-size: 0.9em;
      padding-bottom: 80px;
    }
  }
}
.banner-button-left {
  margin-right: 10px;
}
@media screen and (max-width: 1020px) {
  section .banner-text hr {
    width: 100%;
  }
  section .banner-text {
    padding-bottom: 80px;
  }
  section .background {
    // background-size: cover;
    // background-repeat: no-repeat;
  }
}
</style>
