<template>
  <main>
    <home-modal
      :animation="animationModal"
      :opened="modal"
      @close-modal="handleModal"
    >
      <Login v-if="modal === 'login'" @change-modal="changeModal" />
      <BeCreator v-if="modal === 'be'" @change-modal="changeModal" />
      <HireCreator v-if="modal === 'hire'" @change-modal="changeModal" />
    </home-modal>
    <home-header @open-modal="handleModal" />
    <MainBanner @open-modal="handleModal" />
    <Clients :banner="true" css="banner" />
    <!--<Cards />-->
    <VideoCard />
    <Demonstration />
    <TypeOfServices />
    <Video />
    <Testimonials />
    <!--<Tags />-->
    <Professionals @login="changeModal" />
    <!--<Clients :banner="false" css="" />-->
    <Footer />
  </main>
</template>

<script>
import { TweenMax } from "gsap";
import MainBanner from "./-MainBanner.vue";
import Cards from "./-Cards.vue";
import Demonstration from "./-Demonstration.vue";
import Video from "./-Video.vue";
import TypeOfServices from "./-TypeOfServices.vue";
import Testimonials from "./-Testimonials.vue";
import Professionals from "./-Professionals.vue";
import Tags from "./-Tags.vue";
import Clients from "./-Clients.vue";
import VideoCard from "./-VideoCard.vue";
import Footer from "./-Footer.vue";
import Login from "./Form/Login.vue";
import BeCreator from "./Form/-NewBeCreator.vue";
import HireCreator from "./Form/-NewHireCreator.vue";

export default {
  components: {
    MainBanner,
    Cards,
    Demonstration,
    TypeOfServices,
    Video,
    Testimonials,
    Professionals,
    Tags,
    Clients,
    Footer,
    Login,
    BeCreator,
    HireCreator,
    VideoCard,
  },
  data() {
    return {
      animationModal: false,
      modal: null,
      scroll: true,
    };
  },
  mounted() {
    if (this.$route.query.reset_token || this.$route.query.openlogin) {
      this.handleModal("login");
    }

    if (this.$route.query.newclient) {
      this.handleModal("hire");
    }

    if (this.$route.query.newcreator) {
      this.handleModal("be");
    }

    window.addEventListener(
      "blur",
      () => {
        TweenMax.lagSmoothing(0);
      },
      false
    );

    window.addEventListener(
      "focus",
      () => {
        TweenMax.lagSmoothing(1000, 16);
      },
      false
    );

    // test
    // this.handleModal("be");

  },
  methods: {
    changeModal(name) {
      this.modal = name;
      this.animationModal = true;
    },
    handleModal(type) {
      this.modal = type;
      this.animationModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  font-family: "SF Pro Display", sans-serif;
  overflow-x: hidden;
  background-color: #111111;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 14px;

  }
}
</style>
