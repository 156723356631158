<template>
  <section class="professionals my-5">

    <!-- <div class="container">
      <h2>{{ $t("home.professionals.title") }}</h2>
    </div>
    <div
      class="swiper-container"
      @mouseover="startSlider"
      @mouseleave="pauseSlider"
    >
      <swiper :options="swiperOp" dir="rtl" ref="swiper">
        <swiper-slide
          v-for="professional in professionals"
          :key="professional.name"
          class="swiper-slide"
        >
          <div class="embed-responsive embed-responsive-1by1 mb-1">
            <img
              class="embed-responsive-item professional-image"
              style="object-fit: cover"
              :src="professional.img"
              :alt="professional.name"
            />
          </div>
          <span class="name">
            <a
              v-if="professional.link"
              :href="professional.link"
              target="_blank"
              >{{ professional.name }}</a
            >
            <span v-else>{{ professional.name }}</span>
          </span>
          <span class="role">{{ professional.role }}</span>
        </swiper-slide>
      </swiper>
    </div> -->

    <div class="container">
      <div class="professional-intro my-5">
        <p>
          Faça parte desta <br />
          comunidade agora mesmo
        </p>
        <div class="btn btn-primary w-auto" @click="login">Crie sua conta</div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12"><p class="mb-3">Quem acredita</p></div>
      </div>
      <div class="row d-flex align-items-end">
        <div class="col-4 col-lg-3"><img class="auto" src="https://storage.googleapis.com/creators-static-1/images/home/google_for_startups_logo.svg" /></div>
        <div class="col-4 col-lg-3"><img class="auto px-5 mb-2" src="https://storage.googleapis.com/creators-static-1/images/logo_scale_up_white.png" alt=""></div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  data() {
    return {
      swiperOp: {
        coverflowEffect: {
          slideShadows: false,
          rotate: 30,
        },
        spaceBetween: 32,
        loop: true,
        speed: 5000,
        slidesPerView: 10,
        autoplay: {
          delay: 0,
        },
        breakpoints: {
          1600: {
            slidesPerView: 9,
          },
          1200: {
            slidesPerView: 7,
          },
          1100: {
            slidesPerView: 7,
          },
          1000: {
            slidesPerView: 6,
          },
          900: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 3,
          },
        },
      },
      professionals: [
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/rodrigo.jpg",
          name: "Rodrigo Allgayer",
          role: "CEO & Founder",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/nohoa.jpg?2",
          name: "Nohoa Arcanjo",
          role: "CGO & Co-founder",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/pedro.jpg?2",
          name: "Pedro Emilio",
          role: "CTO & Co-founder",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/thais.jpg?2",
          name: "Thaís Young",
          role: "Operation Head",
        },

        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/andre.jpg",
          name: "André Guerra",
          role: "CX Leader",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/fernanda.jpg?2",
          name: "Fefa Mesquita",
          role: "Community Builder",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/zeka.jpg",
          name: "Zéka Silva",
          role: "Sales Team",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/jess.jpg",
          name: "Jéssica Nery",
          role: "Sales Team",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/vivi.jpg?2",
          name: "Vivi Sena",
          role: "Finance Team",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/rachel.jpg",
          name: "Rachel Teixeira",
          role: "Finance Team",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/larissa.jpg?2",
          name: "Larissa Bittencourt",
          role: "Project Manager",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/enio.jpg?2",
          name: "Enio Xavier",
          role: "Content Strategist",
        },
        {
          img: "https://storage.googleapis.com/creators-static-1/images/home/team/aline.jpg?2",
          name: "Aline Farias",
          role: "Community Success",
        },
      ],
    };
  },
  computed: {
    swiper() {
      this.$refs.swiper.swiper;
    },
  },
  mounted() {
    this.startSlider();
    document.querySelector(
      ".professionals .swiper-wrapper"
    ).style.transitionTimingFunction = "linear";
  },
  methods: {
    pauseSlider() {
      const { swiper } = this.$refs;
      swiper.swiper.autoplay.stop();
    },
    startSlider() {
      const { swiper } = this.$refs;
      swiper.swiper.autoplay.start();
    },
    login() {
      this.$emit("login", "login");
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  h2 {
    color: #ffffff;
    font-size: 2em;
    margin-left: auto;
    margin-right: auto;
    padding: 0 0 20px 0;
  }
  .name,
  .name span {
    font-weight: 600;
    font-size: 15px;
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #ffffff;
  }
  .role {
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    display: inline-block;
    width: 100%;
    padding: 0;
    text-align: center;
    color: #666;
  }
  img {
    max-width: 100%;
    width: auto;
  }
img.google-logo {
    width: 18%;
    margin-right: -11px;
  }
  .professional-image {
    border-radius: 50%;
  }
  .professional-intro {
    padding: 100px 0 20px 0;
  }
  .professional-intro > p:first-child {
    font-size: 32px;
    color: white;
    line-height: 38px;
    margin-bottom: 48px;
    font-weight: bold;
  }
  .professional-intro button {
    background: #7553e2;
    border-radius: 10px;
    color: white;
    padding: 15px 25px;
    font-size: 16px;
    border:none;
    margin-bottom: 64px;
  }
  .professional-intro > p:last-child {
    font-size: 18px;
    color: white;
  }
  .professional-intro .sp-btn {
    display: none;
  }
}
@media screen and (max-width: 1020px) {
  section h2 {
    padding: 20px;
  }
  section .professional-intro {
    padding: 55px 20px;
  }
  .professional-intro > button:nth-child(2) {
    display: none;
  }
  .professional-intro .sp-btn {
    display: flex !important;
    justify-content: space-between;
  }
  section .professional-intro button {
    margin-bottom: 35px;
  }
}
</style>
