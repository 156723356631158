<template>
  <section>
    <h2>{{ $t("home.tags.title") }}</h2>
    <span
      v-for="(tag, index) in tags.slice(0, numberTags)"
      :key="`${tag}-${index}`"
      ref="tags"
      class="tag"
    >{{ tag }}</span>
    <img src="https://storage.googleapis.com/creators-static-1/images/plus.png" alt="Plus Icon" @click="addTag" />
  </section>
</template>

<script>
export default {
  data() {
    return {
      numberTags: 10,
      tags: [

        // "Art Directors",
        // "Copywriters",
        // "Creative Directors",
        // "Designers",
        // "Front-End Developers",
        // "3D Artists",
        // "Backend Developers",
        // "UX Designers",
        // "Strategists",
        // "Filmakers",
        // "Photographers",
        // "Producers",
        // "Illustrators",
        // "VFX Artists",
        // "Motion Designers",
        // "Content Producers",
        // "Brand Designers",

        "Content Creators",
        "Digital Influencers",
        "Designers",
        "Copywriters ",
        "Motion",
        "Strategists",
        "Project Managers",
        "Squad Leaders",
        "Creative Studios",
        "3D Artists",
        "Frontend",
        "UX/UI",
        "Filmmakers",
        "Photographers",
        "Illustrators",
        "Brand Designers",
        "Product Designers",

      ]
    };
  },
  methods: {
    addTag() {
      if (this.numberTags >= this.tags.length) {
        this.tags.push(this.tags[Math.floor(Math.random() * this.tags.length)]);
      }
      this.numberTags = this.numberTags + 1;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  margin: 0 80px 80px 40px;
  @media screen and (max-width: 768px) {
    margin: 0 40px 80px 40px;
  }
  h2 {
    margin-bottom: 40px;
    width: 37%;
    color: #ffffff;
    font-weight: 700;
    line-height: 34px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .tag {
    display: inline-block;
    font-size: 3.1em;
    line-height: 1.1em;
    height: auto;
    font-weight: bold;
    color: #ffffff;
    transition: color 0.2s ease-in-out;
    padding: 1px 7px 1px 0;
    border: 0;
    &:hover {
      color: #7053d9;
    }
  }
  img {
    cursor: pointer;
    margin-bottom: -10px;
    height: 3em;
  }
}
</style>
