<template>
  <section class="container">
    <h2>{{ $t("home.demonstration.title") }}</h2>
    <div class="cards-demonstration">
      <div class="container-texts">
        <div class="cards-demonstration-item">
          <img
            class="icon-card"
            src="/images/home/demonstration/demonstration1.svg"
          />
          <h3>{{ $t("home.demonstration.title1") }}</h3>
          <p>{{ $t("home.demonstration.text1") }}</p>
        </div>
        <div class="cards-demonstration-item">
          <img
            class="icon-card"
            src="/images/home/demonstration/demonstration2.svg"
          />
          <h3>{{ $t("home.demonstration.title2") }}</h3>
          <p>{{ $t("home.demonstration.text2") }}</p>
        </div>
        <div class="cards-demonstration-item">
          <img
            class="icon-card"
            src="/images/home/demonstration/demonstration3.svg"
          />
          <h3>{{ $t("home.demonstration.title3") }}</h3>
          <p>{{ $t("home.demonstration.text3") }}</p>
        </div>
        <div class="cards-demonstration-item">
          <img
            class="icon-card"
            src="/images/home/demonstration/demonstration4.svg"
          />
          <h3>{{ $t("home.demonstration.title4") }}</h3>
          <p>{{ $t("home.demonstration.text4") }}</p>
        </div>
      </div>
      <div>
        <img class="card_cellphone" src="https://storage.googleapis.com/creators-static-1/images/home/mockup.png" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  transition: all 0.2s ease-in-out;
   margin-bottom:40px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    padding: 40px;
  }
  .container-texts {
    grid-area: texts;
    width: 70%;
    padding-top: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 3%;
    justify-items: center;
    margin: 0 0 0 0px;
    transition: all 0.2s ease-in-out;
    padding-bottom: 20px;
    @media (max-width: 768px) {
      width: 100%;
      display: block;
    }
  }
  h2 {
    margin-bottom: 0;
    font-size: 26px;
    line-height: normal;
    color: #ffffff;
    font-weight: bold;
    margin-top: 40px;
    width: 100%;
  }
  h3 {
    font-size: 18px;
    line-height: normal;
    color: #ffffff;
    font-weight: bold;
  }
  p {
    line-height: 22px;
    font-weight: 600;
    word-spacing: 1px;
    font-size: 16px;
    color: #999;
  }
  video {
    cursor: pointer;
    opacity: 0.01;
  }
  .card_cellphone {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .cards-demonstration {
    padding-top: 20px;
    display: flex;
    // grid-template-columns: 65% 35%;
    // grid-gap: 10%;
    // grid-template-areas: "texts photos";
    // justify-items: stretch;
    transition: all 0.2s ease-in-out;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
    @media (max-width: 768px) {
      width: 100%;
      grid-template-columns: 100% 0;
    }
  }
  .cards-demonstration-item {
    background: #0a0a0a;
    border-radius: 16px;
    width: 350px;
    padding: 40px 32px;
    display: block;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 5%;
    }
  }
  .cards-demonstration-item > h3 {
    margin-bottom: 16px;
    margin-top: 46px;
  }
  .icon-card {
    margin-left: 0;
  }
  img,
  video {
    z-index: 1;
    grid-area: photos;
    max-width: 290px;
    align-self: end;
    margin-left: 10%;
    transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out;
    &:hover {
      // transform: scale(1.05);
      ~ img {
        transform: translateX(175%);
      }
    }
    &.desktop {
      width: 100%;
      margin-bottom: 75px;
    }
    &.phone {
      max-width: 300px;
      width: 25%;
      justify-self: end;
      margin-right: 220px;
    }
  }
}
</style>
