<template>
  <div>
    <h2 v-if="!banner">{{ $t("home.clients.title") }}</h2>

    <div class="clients px-lg-3 mx-lg-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col d-lg-none"><span class="clients-title">{{ $t("home.clients.approved") }}</span></div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-4 col-lg" v-for="image in images" :key="image">
            <img class="client" :src="image" />
          </div>
        </div>
      </div>
    </div>

    <h2 v-if="!banner">{{ $t("home.clients.who") }}</h2>
    <img
      src="https://storage.googleapis.com/creators-static-1/images/google.png"
      alt="Google Logo"
      class="google"
      v-if="!banner"
    />
  </div>
</template>

<script>

export default {

  props: ["banner", "css"],

  data() {
    return {
      images: [],
      flickityOptions: {
        initialIndex: 3,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
      },
      slickOptions: {
        slidesToShow: 3,
        // Any other options that can be got from plugin documentation
      },
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const clients = await axios({
        method: "GET",
        url: "/api/v1/clients",
      });
      this.images = clients.data.data.map((client) => client.source);
    },
  },

};
</script>

<style lang="scss" scoped>
.clients {
  position: relative;
  z-index: 9;
  margin: -128px 0 128px;
  .client {
    display: block;
    width: 66%;
    height: auto;
    margin: 0 auto;
  }
  .clients-title {
      display: flex;
      color: #fff;
      text-align: center;
      width: 100%;
      justify-content: center;
  }
}

@media (min-width: 992px) {
  .clients {
    // margin: -200px 0 200px;
    .row {
      flex-wrap: nowrap;
    }
  }
}

.main-clients-mobile {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}
h2 {
  color: #ffffff;
  margin-bottom: 40px;
  font-size: 22px;
}
.images-container {
  .single {
    width: 10.66%;
    @media screen and (max-width: 768px) {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 50%;
        margin: 20px auto;
        max-height: 100%;
        min-height: 100%;
      }
    }
    @media (max-width: 768px) {
      width: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 50%;
        margin: 0;
        max-height: 100%;
        min-height: 100%;
      }
    }
    &.double {
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .VueCarousel-inner {
    justify-content: space-between;
  }
}
.google {
  max-height: 35px;
  max-width: 50%;
}
.flickity-enabled.is-draggable {
  width: 100%;
}
.main-carousel img {
  width: auto;
  height: auto;
  max-width: 100px;
}
.main-carousel {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.flickity-viewport {
  height: 175px !important;
}
.VueCarousel-slide {
  display: flex;
  justify-content: center;
}
</style>
